<template>
  <div class="news">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "HomeBase",
  data() {
    return {
      // home_selected: "member_index",
    };
  },
  components: {},

  created: function () {},
  methods: {},
  mounted() {},
};
</script>
<style lang="scss"></style>
<style media="screen" lang="scss" scoped>
.news {
  height: 100%;
}
</style>
